import React from "react";
import { Box, Stack } from "@mui/material";
import { csv2json } from "json-2-csv";
import { BarChart, axisClasses, chartsGridClasses } from "@mui/x-charts";
import { useParams } from "react-router-dom";
import logo from "./SV_logo_farebne_vektor.svg";

interface DataRow {
  Subject: string;
  Percent: number;
  Votes: number;
  Friendly: string;
}

function App() {
  const [resultsData, setResultsData] = React.useState<DataRow[]>([]);
  const { id } = useParams();
  const pagination = Number(id) || 1;

  const fetchResultsData = () => {
    fetch(
      "https://docs.google.com/spreadsheets/d/1hOVjdewbTslb4Doa1OuEVSAIMT1yYRhFjKMGzrMN55o/gviz/tq?tqx=out:csv&sheet=results"
    )
      .then((res) => res.text())
      .then((text) => {
        setResultsData(csv2json(text) as DataRow[]);
      });
  };

  const fetchOtherData = () => {
    fetch(
      "https://docs.google.com/spreadsheets/d/1hOVjdewbTslb4Doa1OuEVSAIMT1yYRhFjKMGzrMN55o/gviz/tq?tqx=out:csv&sheet=data"
    )
      .then((res) => res.text())
      .then((text) => {
        const data = csv2json(text)[0] as { Counted: number; Turnout: number };
        setCounted(data["Counted"]);
        setVoterTurnout(data["Turnout"]);
      });
  };

  const startFetch = React.useCallback(() => {
    fetchResultsData();
    fetchOtherData();
    setTimeout(startFetch, 30e3);
  }, []);

  React.useEffect(() => {
    startFetch();
  }, [startFetch]);

  const [counted, setCounted] = React.useState<number>();
  const [voterTurnout, setVoterTurnout] = React.useState<number>();

  const RenderTurnout = () => (
    <BarChart
      height={150}
      width={500}
      series={[
        {
          data: voterTurnout ? [Number((voterTurnout * 100).toPrecision(4))] : [],
          stack: "A",
          label: "Účasť",
          color: "#568DFC",
        },
        {
          data: voterTurnout ? [100 - voterTurnout * 100] : [],
          stack: "A",
          color: "#E2E6ED",
        },
      ]}
      layout="horizontal"
      leftAxis={null}
      xAxis={[
        {
          valueFormatter: (v) => `${v}%`,
        },
      ]}
    />
    /* <Box sx={{ ml: "50px" }}>
      <Grid container spacing={0} columns={2}>
        <Grid item>
          <Box sx={{ padding: 2, background: "#f4bf35" }}>
            <Typography variant={"h5"}>Účasť</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ padding: 2, background: "#ffd437" }}>
            <Typography variant={"h5"}>
              {voterTurnout ? (voterTurnout * 100).toFixed(1) : ""}%
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box> */
  );

  const RenderCounted = () => (
    /* <PieChart
      height={200}
      width={500}
      series={[
        {
          data: [
            {
              label: "Sčítané zápisnice",
              value: counted ? Number((counted * 100).toPrecision(4)) : 0,
              color: "#568DFC",
            },
            {
              label: "Nesčítané zápisnice",
              value: counted ? 100 - counted * 100 : 0,
              color: "#E2E6ED",
            },
          ],
          arcLabel: "formattedValue",
          innerRadius: "50%",
          valueFormatter: (v) => `${v.value}%`,
        },
      ]}
    /> */
    <BarChart
      height={150}
      width={500}
      series={[
        {
          data: counted ? [Number((counted * 100).toPrecision(4))] : [],
          stack: "A",
          label: "Sčítané zápisnice",
          color: "#568DFC",
        },
        {
          data: counted ? [100 - counted * 100] : [],
          stack: "A",
          label: "Nesčítané zápisnice",
          color: "#E2E6ED",
        },
      ]}
      layout="horizontal"
      leftAxis={null}
      xAxis={[
        {
          valueFormatter: (v) => `${v}%`,
        },
      ]}
    />
  );

  return (
    <Box
      sx={{ width: 1920, height: 216, position: "static", background: "white" }}
    >
      <Box sx={{ padding: "10px" }}>
        <Stack
          direction={"row"}
          justifyContent={"space-around"}
          alignItems={"center"}
        >
          {pagination === 1 && <RenderCounted />}
          {pagination === 2 && <RenderTurnout />}
          {pagination === 3 && <img src={logo} alt="ŠV logo" height={200} />}
          <BarChart
            width={1400}
            height={200}
            dataset={resultsData
              .map((r) => ({ name: r.Friendly, percent: r.Percent * 100 }))
              .sort((a, b) => b.percent - a.percent)
              .slice((pagination - 1) * 8, pagination * 8)}
            xAxis={[{ dataKey: "name", scaleType: "band" }]}
            yAxis={[
              {
                colorMap: {
                  type: "piecewise",
                  thresholds: [5],
                  colors: ["#4F545B", "#1C53F7"],
                },
                valueFormatter: (v) => `${v}%`,
                hideTooltip: true,
              },
            ]}
            series={[{ dataKey: "percent" }]}
            grid={{ horizontal: true }}
            sx={{
              [`& .${axisClasses.left} .${axisClasses.label}`]: {
                transform: "translateX(-10px)",
              },
              [`& .${chartsGridClasses.line}`]: {
                strokeDasharray: "5 3",
                strokeWidth: 2,
              },
            }}
          />
        </Stack>
      </Box>
    </Box>
  );
}

export default App;
